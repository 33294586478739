import React, { useEffect } from "react";

import "../pages/News/Article.scss";
import Layout from "../components/Layout/Layout";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types";

const BlogPost = ({ data }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <p className="bold">{text}</p>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: ({ data }, children) => (
        <a href={data.uri} target="_blank">
          {children}
        </a>
      ),
      [BLOCKS.DOCUMENT]: (node, children) => {
        return <p>{children}</p>;
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2>{children}</h2>;
      },
    },
  };
  useEffect(() => {
    console.log(data.allContentfulPost, "posts");
  }, []);

  const actualPost = data.allContentfulPost.nodes[0];

  const renderImage = (image) => {
    const imgUrl = image.file.url;
    const imgSrc =
      imgUrl == null ? "" : `https://${imgUrl.substring(2, imgUrl.length)}`;
    return (
      <>
        <p className="bold"> {image.title}</p>
        <img className="news-image" src={imgSrc} alt="" />
        <p> {image.description}</p>
      </>
    );
  };
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">{actualPost.title} </h1>
            <h1 className="title">{actualPost.description} </h1>
            <p>{renderRichText(actualPost.richText, options)}</p>
            {actualPost.multipleImages
              ? actualPost.multipleImages.map((image) => renderImage(image))
              : null}
          </div>
        </div>
        <h1></h1>
      </div>
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query GetPostBySlug($slug: String) {
    allContentfulPost(
      sort: { fields: slug, order: ASC }
      filter: { slug: { eq: $slug } }
    ) {
      nodes {
        title
        slug
        multipleImages {
          title
          description
          file {
            url
          }
        }
        createdAt
        richText {
          raw
        }
      }
    }
  }
`;
